<template>
  <div class="fixed left-4 top-1/3 navigator-container z-50 hidden mobileL:block" v-if="windowTop !== 0">
        <div class="px-2 py-4 bg-white-1 bg-opacity-40 rounded-3xl border border-white-2 flex flex-col gap-4">
            <a @click="changeBrand('laybare')" class="w-14 h-14 2xl:w-16 2xl:h-16 p-2 flex items-center justify-center bg-green-3 rounded-full cursor-pointer">
                <img class="w-full" src="../assets/images/home-page/header/logo2.png" alt="Lay Bare">
            </a>
            <a @click="changeBrand('laybareplus')" class="w-14 h-14 2xl:w-16 2xl:h-16 p-2 flex items-center justify-center bg-green-10 rounded-full cursor-pointer">
                <img class="w-full" src="../assets/images/home-page/Lay-Bare-Plus.png" alt="Lay Bare Plus">
            </a>
            <a @click="changeBrand('passionails')" class="w-14 h-14 2xl:w-16 2xl:h-16 p-2 flex items-center justify-center bg-pink-14 rounded-full cursor-pointer">
                <img class="w-full m-auto" src="../assets/images/home-page/passionails-logo.png" alt="Passionails">
            </a>
            <a @click="changeBrand('lavish-lashes')" class="flex w-14 h-14 2xl:w-16 2xl:h-16 p-2 items-center justify-center bg-magenta-6 rounded-full cursor-pointer">
                <img class="w-full m-auto" src="../assets/images/home-page/lavishlashes-logo.png" alt="Lavish Lashes">
            </a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            windowTop: 0
        }
    },

    mounted() {
        window.addEventListener("scroll", this.onScroll, true)
    },

    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll, true)
    },

    methods: {
        onScroll(e) {
            this.windowTop = e.target.scrollTop;
        },

        changeBrand(brand) {
            let path = this.$route.fullPath.split('/');
            path[1] = brand;
            const finalPath = path.join('/');
            this.$router.push(finalPath);
        }
    }
}
</script>