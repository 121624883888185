<template>
    <div class="h-screen">
      <div class="text-green-1 relative">
        <div class="flex flex-col">
          <Nav class="z-50" />
          <BrandNavigator class="z-50" />

          <router-view class="z-40" />
          
          <Footer class="z-40" />
          
          <div class="popup-overlay" v-show="showPopup">
            <div class="popup-content">
                <span class="close-btn" @click="handleCloseClick">
                    <svg class="thin-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M18.36 6.64a1 1 0 0 0-1.41 0L12 11.59 7.05 6.64a1 1 0 0 0-1.41 1.41L10.59 13l-4.95 4.95a1 1 0 0 0 1.41 1.41L12 14.41l4.95 4.95a1 1 0 0 0 1.41-1.41L13.41 13l4.95-4.95a1 1 0 0 0 0-1.41z"/>
                    </svg>
                </span>
                <iframe class="pop-up-container" src="https://www.youtube-nocookie.com/embed/t_35tc16r9w?si=gCtcWMEGsHIu7vNv&amp;controls=1&amp;modestbranding=1&amp;autoplay=1&amp;mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Nav from '../navigation/LaybareHeader.vue';
  import BrandNavigator from '../BrandNavigator.vue';
  import Footer from '../footer/LaybareFooter.vue'
  export default {
    components: {
      Nav,
      BrandNavigator,
      Footer,
    },

    data() {
      return {
        showPopup: !sessionStorage.getItem('popupDisplayed')
      }
    },

    methods: {
      closePopup() {
        this.showPopup = false;
        sessionStorage.setItem('popupDisplayed', true);
        let iframe = document.querySelector('iframe');
        iframe.remove();
      },

      handleCloseClick() {
        this.closePopup();
        this.trackCloseButtonClick();
      },

      trackCloseButtonClick() {
        if (process.env.VUE_APP_NODE_ENV !== "production") return;

        this.$gtag.event('click', {
          event_category: 'Popup',
          event_label: 'Close Button',
          value: 1
        });
      }
    }
  }
  </script>

  <style scoped>
    .pop-up-container {
    width: 560px;
    height: 315px;
  }

  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  .popup-content {
    position: relative;
  }

  .close-btn {
    position: absolute;
    top: -37px;
    right: -35px;
    cursor: pointer;
    z-index: 1;
  }

  .close-btn:hover::before {
    content: "";
    position: absolute;
    inset: -3px;
    background-color: #36454F;
    border-radius: 50%;
    z-index: -1;
  }

  .thin-icon {
    width: 30px;
    height: 30px;
    fill: #818589;
    position: relative;
    z-index: 2;
  }

  .no-scroll {
    overflow: hidden;
  }

  @media (max-width: 480px) {
      .pop-up-container {
        width: 300px;
        height: 168.75px;
      }

      .close-btn {
        top: -25px;
        right: -20px;
        width: 22px;
        height: 22px;
      }

      .thin-icon {
        width: 22px;
        height: 22px;
      }
  }

  @media (min-width: 1280px) {
      .pop-up-container {
        width: 900px;
        height: 506.25px;
      }
  }

  @media (min-width: 1536px) {
      .pop-up-container {
        width: 1100px;
        height: 618.75px;
      }
  }
  </style>
  